.progressBar{
  height: 10px;
}

.group_heading_wrapper{
  font-weight: bold;
  background-color: #edeaea;
  &:hover{
    background: #ddd;
  }
}

.numberCircle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 2px;
  background: #fff;
  border: 1px solid #666;
  color: #666;
  text-align: center;
  font: 15px Arial, sans-serif;
}

.day_hours{
  float: left;
  width: 100%;
  .hours_select{
    float: left;
    padding: 0;
    width: 100%;
    margin: 10px 0 0;
    list-style-type: none;
    position: relative;
    & > li{
      margin-right: 10px;
      overflow: hidden;
      width: 100px;
    }
  }
}

$color1: #f4f4f4;
$color2: #3197EE;

.thumbnail_container {
  width: 100%;
  height: 104px;
  position: 'relative';
}

.camera__holder {

  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;

  span {
    position: absolute;
    top: -5px;
    right: -10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    svg {
      position: absolute;
      width: 30px;
    }
  }
}
.icon__container {
  position: 'absolute';
  // top: -9px;
  // right: -px;
}
.file_name_container {
  display: inline-flex;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
}
.file_name {
  color: #d9534f;
}

.icon__c {
  background: #fff;
  border-radius: 50%;
  width: 10px;
}
.file_uploader_input {
  padding: 10px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}
.dropzone_input {
  *:focus {
    outline: none;
  }
}
.uploader_title {
  font-weight: 700;
  font-size: 20px;
}
.upload_button {
  color: azure;
  margin: 5px 0px;
}

.remove_icon {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  color: red;
  //background-color: rgb(237, 242, 245);
  //border-radius: 50%;
  font-size: 28px;
  width: 28px;
  cursor: pointer;
}
.thumbInner {
  cursor: move;
  display: flex;
  min-width: 0;
  overflow: hidden;
}
.img {
  display: block;
  width: auto;
  height: 100%;
  z-index: 0;
  // padding: 4px 0px 6px 0px;
  object-fit: contain;
}
.thumb {
  display: inline-flex;
  border-radius: 2px;
  // border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 105px;
  height: 105px;
  position: relative;

  box-sizing: border-box;
}
.thumbsContainer {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
}
.image_remaining {
  color: red;
}

.add_business_hours_24{
  margin-top: 25px;
}

.add_business_hours_btn{
  padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 25px;
 
}

.add_social_media_btn{
  margin-top: 44px;
  padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
}

