.board-wrapper {
    display: block;
    background: darken($content-bg, 3.5%);
    @media (max-width: 768px) {
        width: 100%;
    }
  
    .board-portlet {
        border-radius: 4px;
        margin-left: 0;
        .dropdown {
          .dropdown-toggle {
              &:after {
                 display: none; 
              }
          }
        }
    }
  
    .portlet-heading {
        font-weight: 500;
        margin-bottom: 5px;
    }
  
    .task-number {
        color: $text-muted;
    }
  
    .portlet-card-list {
        padding-left: 0;
        list-style: none;
        min-height: 70px;
        margin-bottom: 16px;
    }
  
    .portlet-card {
        width: 100%;
        border-radius: 4px;
        padding: 20px 20px 20px 20px;
        background: $card-bg;
        border-radius: 6px;
        position: relative;
        margin-bottom: 15px;
        margin-bottom: 0;
        cursor: grab;
  
        .progress {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            border-radius: 6px 6px 0px 0px;
            height: 4px;
  
            .progress-bar {
                border-radius: 0px;
            }
        }
  
        .task-date {
            margin-bottom: 5px;
            color: $text-muted;
            grid-row-start: 1;
            grid-column-start: 1;
        }
  
        .action-dropdown {
            grid-row-start: 1;
            grid-column-start: 2;
            margin-left: auto;
  
            .dropdown-toggle {
                border: none;
                background: inherit;
  
                i {
                    font-size: 20px;
                    line-height: 20px;
  
                    &:before {
                        margin-right: -10px;
                    }
                }
  
                &:after {
                    display: none;
                }
            }
  
            .dropdown-menu-right {
                left: 0;
            }
        }
  
        .task-title {
            font-weight: 500;
            grid-row-start: 2;
            grid-column-start: 1;
            grid-column-end: 3;
            font-size: 16px;
            margin-bottom: 15px;
        }
  
        .image-grouped {
            grid-row-start: 3;
            grid-column-start: 1;
            grid-column-end: 3;
        }
  
        .portlet-image {
            width: 100%;
            display: block;
            grid-row-start: 4;
            grid-column-start: 1;
            grid-column-end: 3;
            margin-top: 5px;
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
        }
  
        .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-row-start: 5;
            grid-column-start: 1;
            grid-column-end: 2;
            margin-top: 20px;
        }
  
        .due-date {
            grid-row-start: 5;
            grid-column-start: 2;
            grid-column-end: 3;
            margin-bottom: 0;
            text-align: right;
            margin-top: 20px;
            color: $text-muted;
            font-weight: 500;
        }
    }
  
    .add-portlet {
        background: $card-bg;
        border-radius: 6px;
        padding: 25px 10px;
        text-align: center;
        color: $text-muted;
        font-weight: 500;
        border: none;
        width: 100%;
        transition-duration: 0.4s;
        transition-property: "background";
  
        &:hover {
            background: darken($card-bg-varient, 5%);
        }
    }
  
    @media (max-width: 768px) {
        overflow-x: auto;
        .board-portlet{
            min-width: 100%;
            width: 100%;
        }
    }
  }
  
  .kanban-toolbar {
    .btn {
        margin-left: 10px;
    }
  }
  
  .wrapper {
      .private-btn {
          background-color: $private-btn-bg;
      }
  }
  .kanbanHeight {
      min-height: 100%;
  }